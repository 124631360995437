<template>
  <div>
    <!-- 交易订单 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
      <!-- 搜索栏 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
        <el-form-item>
           <el-date-picker style="width:230px" size="small" v-model="timeRange" @change="getTimeRange($event,'startDate','endDate','queryParams')" type="daterange" value-format="yyyy-MM-dd" :picker-options="beforeCurTime" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" align="right"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select v-model="queryParams.orderType" placeholder="请选择套餐类型" clearable filterable @change="refresh">
            <el-option v-for="item in orderOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          
        </el-form-item>
        
        <el-form-item>
          <el-input v-model="queryParams.orderNum" clearable placeholder="输入订单号搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="queryParams.account" clearable placeholder="输入学号搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="queryParams.content" clearable placeholder="输入套餐名称搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" icon="el-icon-search" @click="refresh">搜索</el-button>
        </el-form-item>
      </el-form>

      <div class="content-wrap">
        <!-- <div class="btn-wrap cfx">
          <el-button type="success" size="small" icon="el-icon-plus" >添加套餐</el-button>
        </div> -->
    
      <!--列表-->
        <paged-table
          :data="dealOrderList"
          :total="dealOrderTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @refresh="refresh"
        >
          <el-table-column
            prop="orderNum"
            width="170px"
            label="订单号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="extend.userName"
            label="用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="account"
            label="学号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="extend.schoolName"
            label="学校"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="orderType"
            label="类型"
            :show-overflow-tooltip="true"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.orderType == 1">套餐充值</span>
            <span v-if="scope.row.orderType == 2">套餐续订</span>
            <span v-if="scope.row.orderType == 3">套餐升级</span>
            <span v-if="scope.row.orderType == 4">套餐报停</span>
            <span v-if="scope.row.orderType == 5">套餐复通</span>
            <span v-if="scope.row.orderType == 6">套餐变更</span>
            <span v-if="scope.row.orderType == 7">手机号购买</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="content"
            label="套餐名称"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="orderStatus"
            label="状态"
            :show-overflow-tooltip="true"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.orderStatus == 0">等待支付</span>
            <span v-if="scope.row.orderStatus == 1">交易成功</span>
            <span v-if="scope.row.orderStatus == 2">已退款</span>
            <span v-if="scope.row.orderStatus == 4">交易关闭</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="oriPrice"
            label="价格（元）"
            width="90px"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{(scope.row.oriPrice/100).toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column
            prop="payPrice"
            width="90px"
            label="实付（元）"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{(scope.row.payPrice/100).toFixed(2)}}
            </template>
          </el-table-column>
          <el-table-column
            prop="payType"
            label="支付方式"
            :show-overflow-tooltip="true"
          >
          <template slot-scope="scope">
            <span v-if="scope.row.payType == 1">微信支付</span>
            <span v-if="scope.row.payType == 2">支付宝</span>
            <span v-if="scope.row.payType == 3">余额支付</span>
            <span v-if="scope.row.payType == 4">线下支付</span>
          </template>
          </el-table-column>
          <el-table-column
            prop="payTime"
            label="支付时间"
            :show-overflow-tooltip="true">
          </el-table-column>
          <el-table-column align="right" label="操作"
            width="90" fixed="right" >
            <template slot-scope="scope">
              <el-button size="mini" type="warning" @click="handleOrder(scope.row)" v-if="scope.row.orderStatus==1"
                icon="el-icon-s-order">退款</el-button>
              <!-- <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                title="编辑"
              ></el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                title="删除"
              ></el-button> -->
            </template>
          </el-table-column>
        </paged-table>
      </div>

    </area-tree>
    <!-- 退款 -->
    <el-dialog title="退款" :visible.sync="orderVisible" width="500px" :close-on-click-modal="false">
      <el-form label-width="110px" size="small">
        <el-form-item label="退款金额：" prop="orderNum" style="width: 90%">
          <el-input v-model="orderForm.refundFee" type="number" placeholder="请输入退款金额" clearable></el-input>
          <div style="font-size:14px; color:#ccc;padding:0">退款金额为 0 - {{ (ordermoney/100).toFixed(2) }} 元</div>
        </el-form-item>
        <el-form-item style="width: 60%">
          <el-button size="small" @click="orderVisible = false">取消</el-button>
          <el-button size="small" type="primary" @click="submitOrder">确认</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import datePicker from "@/mixins/datePicker";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'dealOrder',
  components: {
    AreaTree,
    PagedTable
  },
  mixins: [pageMixin,provinces,download,datePicker],
  data() {
    return {
      url: '',
      BaseUrl,
      queryParams: {
        orderNum: '',
        account: '',
        content: '',
      },
      schoolType: [],
      form:{
        id:'',
        money: '',
      },
      orderVisible: false,
      ordermoney: '',
      orderOption: [],
      orderOptions:[
        {
          value:1,
          label:'套餐充值'
        },
        {
          value:2,
          label:'套餐续订'
        },
        {
          value:3,
          label:'套餐升级'
        },
        {
          value:4,
          label:'套餐报停'
        },
        {
          value:5,
          label:'套餐复通'
        },
        {
          value:6,
          label:'套餐变更'
        },
        {
          value:7,
          label:'卡号套餐'
        },
        {
          value:8,
          label:'手机号'
        }
      ],
      orderForm: {
        id: null,
        totalFee: '',
        refundFee: '',
      },
      payType: null,
      mp: null,
      details:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      pageApi:'getDealOrderList',

      
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("dealOrder", ["dealOrderList", "dealOrderTotal"]),
  },
  methods: {
    ...mapActions("commonOptions", ["getAppRoleUsers"]),
    ...mapActions("fuseOrder",[ "cardOrderAssign" ]),
    ...mapActions("dealOrder",[ "getDealOrderList", "payAlipayRefund", "payWxpayRefund", "payWxpayRefundMp","payYepayRefund"]),
    async handleOrder(row) {
      console.log(row)
      this.orderForm.id = row.id
      this.orderForm.outTradeNo = row.orderNum
      this.orderForm.totalFee = row.payPrice
      this.payType = row.payType
      this.mp = row.mp
      this.orderVisible = true
      this.ordermoney = row.payPrice
    },
    async submitOrder() {
      if( !this.orderForm.refundFee ){
        return;
      }else{
        this.orderForm.refundFee = parseInt(parseFloat(this.orderForm.refundFee)*100)
        try{ 
          if( this.payType == 2 ){
            let res = await this.payAlipayRefund({ ...this.orderForm })
          }else if( this.payType == 1 ){
            if(this.mp == 0){
                let resB = await this.payWxpayRefund({ ...this.orderForm })
            }else if(this.mp == 1){
                let resB = await this.payWxpayRefundMp({ ...this.orderForm })
            }            
          }else{
            let resC = await this.getDealOrderList({ ...this.orderForm })
          }
          this.$message.success("退款成功")
        }catch(error){}finally{
          this.orderVisible = false
          this.orderForm.refundFee = ''
        }
      }
    },
    handleQuery(data){
      // log(data)
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
        this.queryParams.schoolId = data.id;
      }
        this.refresh()
    },
  },
  mounted(){
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
    this.refresh()
  }
};
</script>